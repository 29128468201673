<template>
    <div>
        <v-card-text>
            <v-form disabled @submit.prevent="createAttachments" ref="form">
                <v-container>
                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="arquivos"
                            :items-per-page="20"
                            class="elevation-1">
                            <template v-slot:[`item.actions`]="{ item }" :resource="item">
                                <v-icon 
                                    size="30"
                                    class="icon"
                                    color="blue" 
                                    align-item="center"
                                @click="downloadAttachments(item.id)">mdi-download-circle
                                </v-icon>
                            </template>
                        </v-data-table>
                    </template>
                   
                    <p class="space-personalized"></p>
                    
                    <div class="buttons">
                        <v-row>
                            <v-btn class="rounded-lg" elevation="0" color="green" @click="$router.push('/doador')">
                                <span class="textBtn">Sair</span>
                            </v-btn>
                        </v-row>
                    </div>
                </v-container>
            </v-form>
        </v-card-text>
        <v-snackbar absolute background-color="white" color="green" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios";
import fileDownload from "js-file-download";

export default {
    data() {
        return {
            tab:2,
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            anexosForm: {
                donor_id:""
            },
            headers: [
            {
                text: "Nome do exame",
                align: "center",
                value: "attachment_name",
            },
            {   text: "Download", 
                value: "actions", 
                align: "center",
                sortable: false 
            },
            ],
            dialogDonor:false,
            donor_id: "",
            loading: true,
            options: {},
            donor:[],
            data:[],
            arquivos: [],
            status_serology: [],
            selected:"",
            message: "",
            snackbar: false,
            messageError: "",
            unity: "",
            donorID: "",
            donorName: "",
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    created() {
        this.donorID = this.$route.query.item.id
        axios.get("/attachments").then((response) => {
            this.arquivos = response.data.data;
            this.arquivos = this.arquivos.filter((x) => x.donor_id == this.donorID);
        });
    },

    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {
        indoDataTable() {
            this.loading = true;
        },
        
        openDialog() {
            this.dialogDonor = true;
        },

        downloadAttachments(id) {
            axios.get(`/attachments/download/${id}`, {
                responseType:"blob",
            }).then((response) => {
                let fileType = "";
                if  (response.data.type == "application/pdf") fileType = "pdf";
                if  (response.data.type == "image/png") fileType = "png";
                if  (response.data.type == "image/jpeg") fileType = "jpeg";

                fileDownload(response.data, `doc.${fileType}`);
            });
        }
    },

    mounted() {
        this.indoDataTable();
    },

};
</script>
  
<style scoped>
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    /* font-weight: bold; */
}

.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

.cardFooter {
    padding: 0 !important;
}
</style>
  