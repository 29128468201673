<template>
    <div>
        <v-card flat>
        <v-card-text>
        <v-form disabled @submit.prevent="createDoadorReports" ref="form">
            <v-container>
            <v-row>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-text-field 
                    color="#086318" 
                    :rules="formRules" 
                    label="Data de Admissão"
                    type="date" 
                    outlined 
                    v-model="reportsForm.admission_date"
                    dense></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="1">
                    <v-text-field 
                    color="#086318" 
                    label="Altura"
                    v-mask="'#.##'"
                    maxlength="4" 
                    outlined
                    dense
                    v-model="reportsForm.height">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="1">
                    <v-text-field 
                    color="#086318" 
                    label="Peso"
                    v-mask="'##.##'"
                    maxlength="7" 
                    outlined
                    dense
                    v-model="reportsForm.weight">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-select 
                    color="#086318" 
                    v-model="reportsForm.diagnostic_id" 
                    :items="diagnostics"
                    item-text="name" 
                    item-value="id" 
                    dense 
                    outlined
                    label="Suspeita de Diagnóstico">
                    </v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                    <v-select 
                    color="#086318" 
                    v-model="reportsForm.comorbidity_id" 
                    :items="comorbidities"
                    item-text="name" 
                    item-value="id" 
                    dense 
                    outlined
                    label="Comorbidades">
                    </v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="4" v-if="reportsForm.comorbidity_id == 5">
                    <v-text-field 
                    color="#086318" 
                    label="Causa Provável da Morte"
                    outlined
                    dense
                    v-model="reportsForm.probable_cause">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.donor_diabetes">
                        <p>Diabetes no doador?</p>

                        <v-radio label="Sim" 
                        value="1" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="Não" 
                        value="2" 
                        color="#086318"
                        ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="3">
                    <v-radio-group :rules="formRules" v-model="reportsForm.diabetes_relatives">

                    <p>Diabetes em parentes de 1ª grau?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.bronchoaspiration">
                    <p>Broncoaspiração no Internamento?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>


                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.inpatient_dialysis">

                    <p>Diálise no Internamento?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex" cols="12" sm="1">
                    <v-radio-group :rules="formRules" v-model="reportsForm.dst">
                    <p>DST?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="1">
                    <v-radio-group :rules="formRules" v-model="reportsForm.smoker">
                    <p>Tabagista?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2" :rules="formRules" v-if="reportsForm.smoker == 1">
                    <v-text-field 
                    color="#086318" 
                    label="Há quanto tempo?" 
                    dense
                    v-model="reportsForm.how_long" 
                    ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="1">
                    <v-radio-group :rules="formRules" v-model="reportsForm.previous_surgery">
                    <p>Cirúrgia Prévia?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2" :rules="formRules" v-if="reportsForm.previous_surgery == 1">
                    <v-text-field 
                    color="#086318" 
                    label="Qual?" 
                    dense
                    v-model="reportsForm.which_surgery" 
                    ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="1">
                    <v-radio-group :rules="formRules" v-model="reportsForm.heart_disease">

                    <p>Cardiopatia?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2" :rules="formRules" v-if="reportsForm.heart_disease == 1">
                    <v-text-field 
                    color="#086318" 
                    label="Qual?" 
                    dense
                    v-model="reportsForm.which_heart_disease" 
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.has">
                    <p>HAS?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.asthma">
                    <p>Asma?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.dpoc">
                    <p>DPOC?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.infectious_disease">
                    <p>Doença Infectocontagiosa?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2" :rules="formRules" v-if="reportsForm.infectious_disease == 1">
                    <v-text-field 
                    color="#086318" 
                    label="Qual?" 
                    dense
                    v-model="reportsForm.which_infectious" 
                    ></v-text-field>
                </v-col>
            </v-row>
            <br>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.facial_trauma">
                  <p>Trauma na face?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.corneal_surgery">
                  <p>Relato de cirurgia na córnea?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1">
                  <v-radio-group :rules="formRules" v-model="reportsForm.tattoo">
                  <p>Tatuagens?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1" v-if="reportsForm.tattoo == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Tempo:" 
                  dense
                  v-model="reportsForm.tattoo_time" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.illicit_drugs">
                  <p>Usuários de drogas ilícitas?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.injecting_drugs">
                <v-textarea 
                  color="#086318" 
                  label="Quais?"
                  row-height="12"
                  rows="4"
                  outlined
                  dense
                  v-model="reportsForm.injecting_drugs">
                  </v-textarea>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.non_injecting_drugs">
                <v-textarea 
                  color="#086318" 
                  label="Quais?"
                  row-height="12"
                  rows="4"
                  outlined
                  dense
                  v-model="reportsForm.non_injecting_drugs">
                  </v-textarea>
              </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.piercing">
                  <p>Piercings?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.alcoholic">
                  <p>Alcoolista?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-text-field 
                  color="#086318" 
                  label="Tipo:" 
                  dense
                  v-model="reportsForm.type_of_alcoholic" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-text-field 
                  color="#086318" 
                  label="Tempo:" 
                  dense
                  v-model="reportsForm.time_of_alcoholic" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-text-field 
                  color="#086318" 
                  label="Frequência:" 
                  dense
                  v-model="reportsForm.frequency_of_alcoholic" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="7">
                <v-textarea  
                  color="#086318" 
                  label="Outros"
                  outlined
                  row-height="15"
                  rows="6"
                  dense
                  v-model="reportsForm.others">
                  </v-textarea >
              </v-col>
          </v-row>
          <br>
          <!-- <h3>Antecedentes:</h3>
          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
                  <v-radio-group :rules="formRules" v-model="reportsForm.blood_receiver">
                  <p>Recebeu sangue e Hemoderivados?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.blood_receiver == 1">
                <v-text-field 
                  color="#086318" 
                  label="Tipo:"
                  outlined
                  dense
                  v-model="reportsForm.type_of_blood_receiver">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.blood_receiver == 1">
                <v-text-field 
                  color="#086318" 
                  label="Volume:"
                  outlined
                  dense
                  v-model="reportsForm.volume_of_blood_receiver">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.blood_receiver == 1">
                <v-text-field 
                  color="#086318" 
                  label="Data:"
                  type="date" 
                  outlined 
                  v-model="reportsForm.data_of_blood_receiver"
                  dense></v-text-field>
              </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
                  <v-radio-group :rules="formRules" v-model="reportsForm.positive_blood_culture">
                  <p>Hemocultura Positiva?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.positive_blood_culture == 1">
                <v-text-field 
                  color="#086318" 
                  label="Germe Isolado:"
                  outlined
                  dense
                  v-model="reportsForm.isolated_germ">
                  </v-text-field>
              </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.infection">
                  <p>Infecção?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
                  <v-radio-group :rules="formRules" v-model="reportsForm.material_for_serology">
                  <p>Coleta de material para sorologia?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.material_for_serology == 1">
                <v-text-field 
                  color="#086318" 
                  label="Local da coleta:"
                  outlined
                  dense
                  v-model="reportsForm.collection_location">
                  </v-text-field>
              </v-col>
          </v-row> -->

          <!-- <template> 
            <v-data-table
              :headers="headers"
              :items="donorInfection"
              :items-per-page="10"
              class="elevation-1"
            >
            </v-data-table>
          </template> -->

            <!-- <p class="space-personalized"></p> -->
            <div class="buttons">
                <v-row>
                <v-btn class="rounded-lg"  @click="$router.push('/doador')" elevation="0" color="green">
                    <span class="textBtn"> Sair </span>
                </v-btn>
                </v-row>
            </div>
            </v-container>
        </v-form>
        </v-card-text>

        <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
              </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</div>
</template>
<script>

import axios from "@/axios";
import FormatDate from "@/utils/FormatDate"
// import { isArgumentsObject } from "util/types";

export default {
  data() {
    return {
      headers: [
            {
              text: "Local da Infecção",
              value: "site_of_infection",
              align: "center",
            },
            {
              text: "Antibiótico",
              value: "infection_antibiotic",
              align: "center",
            },
            {
              text: "Dose",
              value: "dose_of_antibiotic",
              align: "center",
            },
            {
              text: "Data",
              value: "antibiotic_start_date",
              align: "center",
            },
      ],
      page: 1,
      totalUsers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      sortBy: "name",
      message: "",
      snackbar: false,
      loadingBTN: false,
      hospitalSelected: "",
      exibirMensagem: false,
      messageError: "",
      reportsForm: {
        donor_id: "",
        clinical_reports_id: ""
      },
      infectionForm:{
      donor_id: ""
      },
      diagnostics: [],
      comorbidities: [],
      vue: {
        hasDST: null,
        isSmoker: null,
        hasSugery: null,
        hasInfectiousDiesease: null
      },
      donorID: "",
      donorBack: [],
      donorInfection:[],
      editForm: {
        
      },
      formRules: [
        (v) => !!v || "Este campo é obrigatório",
      ],
    };
  },
  
  created() {
    this.donorID = this.$route.query.item.id
   
    axios.get("/clinical_reports").then((response) => {
        this.donorBack = response.data.data;
        this.reportsForm = this.donorBack.filter((x) => x.donor_id == this.donorID)[0];
        this.reportsForm.comorbidity_id = parseInt(this.reportsForm.comorbidity_id)
        this.reportsForm.diagnostic_id = parseInt(this.reportsForm.diagnostic_id)
        this.reportsForm.admission_date = FormatDate.dataAtualFormatada(this.reportsForm.admission_date)
    });

    axios.get("/infections").then((response) => {
        this.donorInfection = response.data.data;
        this.donorInfection = this.donorInfection.filter((x) => x.donor_id == this.donorID);
        this.donorInfection.antibiotic_start_date = FormatDate.dataAtualFormatada(this.donorInfection.antibiotic_start_date)
    });

    axios.get("/diagnostics").then((response) => {
      this.diagnostics = response.data.data;
    });

    axios.get("/comorbidities").then((response) => {
      this.comorbidities = response.data.data;
    });
  },

  watch: {

    sortBy: {
      handler() {

      }
    },

    options: {
      handler() {
        this.indoDataTable();
      },
    },

    deep: true,
  },

  methods: {

    indoDataTable() {
      this.loading = true;
    },

    openCreateDoador() {
      this.dialog = true;
    },

    clear() {
      this.reportsForm = ""
    },

    createDoadorReports(){
      if(this.$refs.form.validate()) {
        const newReports = (this.reportsForm)
        axios
          .put(`/clinical_reports/${newReports.id}`, newReports)
          .then((response) => {
            this.indoDataTable();
            this.message = response.data.message;
            this.snackbar = true;
            this.$router.push({ name: "Doadores" });
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },

    mounted() {
      this.indoDataTable();
    },
  }

};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.spacediferent {
  width: 7px;
}

.space-personalized {
  padding: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #27ae60,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #ee106d,
      #e46987);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner>h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
