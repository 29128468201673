<template>
  <div>
    <v-card>
      <v-tabs fixed-tabs background-color="green" dark>
        <v-tabs-slider color="#086318"></v-tabs-slider>
        <v-tab>DADOS DOADOR</v-tab>
        <v-tab>Informes Clínicos</v-tab>
        <v-tab>Sorologia</v-tab>
        <v-tab>Anexos</v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-form @submit.prevent="editDoador" disabled v-model="item" ref="form">
                <v-container>
                  <v-row>
                    <!-- <v-col class="d-flex" cols="12" sm="4"> área solicitou a remoção desse campo (05/02/2024)
                      <v-radio-group v-model="donorForm.kind_of_donor_id">
                        <p>Tipo de Doador:</p>

                        <v-radio label="PCR" 
                        value="1" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="ME " 
                        value="2" 
                        color="#086318"
                        ></v-radio>
                      </v-radio-group>

                    </v-col> -->
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group disabled :rules="formRules" v-model="vue.identified">
                        <p>Doador Identificado?</p>

                        <v-radio label="Sim" 
                        value="true" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="Não" 
                        value="false" 
                        color="#086318"
                        ></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group 
                      :rules="formRules" 
                      v-model="donorForm.donor_status_id"
                      >

                      <p>Doador Vivo?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2 
                        color="#086318"></v-radio>
                      </v-radio-group>

                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4" v-if="this.$store.state.profile_id == 1">
                      <v-select 
                      :items="hospital"
                      :rules="formRules"
                      color="#086318" 
                      v-model="donorForm.hospital_id"
                      item-value="id"
                      item-text="hospital"  
                      dense 
                      outlined 
                      label="Hospital Notificante">
                      </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2">
                      <v-text-field
                      color="#086318" 
                      label="RGCT" 
                      :rules="formRules" 
                      placeholder="Apenas Números" 
                      outlined
                      maxlength="14" 
                      v-mask="'##############'"
                      v-model="donorForm.rgct" 
                      cpf  
                      dense></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <h3 row>Informações Pessoais</h3>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4" v-if="vue.identified == 'true'">
                      <v-radio-group  
                      v-model="vue.hasCpf"
                      disabled
                      :rules="formRules">

                        <p>Possui CPF?</p>

                        <v-radio label="Sim"
                         value="true" 
                         color="#086318"
                         ></v-radio>

                        <v-radio label="Não"
                         value="false" 
                         color="#086318"
                         ></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group 
                      :rules="formRules" 
                      v-model="donorForm.sex_id">

                        <p>Gênero</p>

                        <v-radio 
                        label="Feminino" 
                        value=1 
                        color="#086318"
                        ></v-radio>

                        <v-radio 
                        label="Masculino" 
                        value=2 
                        color="#086318"
                        ></v-radio>

                        <v-radio 
                        label="Outros" 
                        value=3
                        color="#086318"
                        ></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4" v-if="vue.identified == 'true'">
                      <v-radio-group 
                      :rules="formRules" 
                      disabled
                      v-model="vue.minor">

                        <p>Menor de Idade?</p>

                        <v-radio 
                        label="Sim" 
                        value="true" 
                        color="#086318"
                        ></v-radio>

                        <v-radio 
                        label="Não" 
                        value="false" 
                        color="#086318"
                        ></v-radio>

                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="3" v-if="vue.hasCpf == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="CPF" 
                      placeholder="CPF" 
                      outlined
                      v-model="donorForm.cpf" 
                      v-mask="'###.###.###-##'" 
                      maxlength="14"
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="5">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Nome" 
                      placeholder="Nome completo" 
                      outlined
                      v-model="donorForm.name" 
                      dense></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="3" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Data de Nascimento"
          
                      type="date" 
                      outlined 
                      v-model="donorForm.birth_date"
                      dense></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Nome da Mãe" 
                      placeholder="Nome da mãe"
                      outlined 
                      v-model="donorForm.mother_name" 
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Telefone" 
                      placeholder="Telefone" 
                      outlined
                      v-mask="'(##)#####-####'" 
                      maxlength="14"
                      v-model="donorForm.telephone" 
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Cartão do SUS" 
                      placeholder="Cartão do SUS"
                      maxlength="15" 
                      v-mask="'#############'" 
                      outlined 
                      v-model="donorForm.sus_card" 
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="3" v-if="vue.identified == 'true'">
                        <v-select 
                        color="#086318" 
                        v-model="donorForm.country_id" 
                        :items="countries"
                        item-text="country_name" 
                        item-value="id" 
                        dense 
                        outlined
                        label="Nacionalidade">
                        </v-select>
                      </v-col>
                  </v-row>

                  <div class="address" v-if="vue.identified == 'true'">
                    <v-row>
                      <h3 class="titles" 
                      row>Endereço</h3>
                    </v-row>

                    <v-row>
                      <v-col class="d-flex" cols="12" sm="3">
                        <v-select 
                        color="#086318" 
                        s="uf" 
                        v-model="donorForm.state_id"
                        :items="state"
                        item-text="name" 
                        item-value="id" 
                        dense 
                        outlined 
                        label="Estado">
                        </v-select>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        items="county" 
                        v-model="donorForm.county"
                        item-text="name" 
                        item-value="id" 
                        dense 
                        outlined 
                        label="Cidade">
                        </v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="Bairro" 
                        placeholder="Bairro" 
                        outlined
                        v-model="donorForm.district" 
                        dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="5">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="Endereço"
                        placeholder="Ex: Rua X, Loteamento Y..." 
                        outlined 
                        v-model="donorForm.address"
                        dense
                        ></v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="4">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="Complemento"
                        placeholder="Ex: Próximo ao Mercadinho..." 
                        outlined 
                        v-model="donorForm.complement"
                        dense
                        ></v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="2">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="CEP" 
                        placeholder=""
                        outlined
                        v-model="donorForm.zip_code" 
                        dense>
                        </v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="1">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="N°" 
                        placeholder="" 
                        outlined
                        v-model="donorForm.number"
                         dense></v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="responsible" v-if="vue.minor == 'true'">
                    <v-row>
                      <h3 class="titles" row>Responsável</h3>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="7">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="Nome do Responsável"
                        placeholder="Nome completo" 
                        outlined 
                        v-model="donorForm.responsible"
                        dense>
                      </v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="5">
                        <v-select 
                          color="#086318"  
                          :items="kinships"
                          v-model="donorForm.kinship_id" 
                          item-text="name"
                          item-value="id" 
                          dense
                          outlined
                          label="Grau de Parentesco">
                        </v-select>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="buttons">
                    <v-row>
                    <v-btn class="rounded-lg"  @click="$router.push('/doador')" elevation="0" color="green">
                        <span class="textBtn"> Sair </span>
                    </v-btn>
                    </v-row>
                </div>
                </v-container>  
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <VisualizarInformesClinicos />
        </v-tab-item>

        <v-tab-item>
          <VisualizarSorologia />
        </v-tab-item>

        <v-tab-item>
          <VisualizarAnexos />
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
  </v-snackbar>
  </div>
</template>  
<script>

import axios from "@/axios";
import FormatDate from "@/utils/FormatDate"
import VisualizarInformesClinicos from "./informesClínicos/VisualizarInformesClinicos.vue"
import VisualizarSorologia from "./sorologia/VisualizarSorologia-comp.vue";
import VisualizarAnexos from "./anexos/VisualizarAnexos-comp.vue";

export default {
  data() {
    return {
      item: "",
      donnoreditid: "",
      page: 1,
      headers: [
          {
            text: "Orgão Doado",
            value: "name",
            align: "center",
          }
      ],
      donor: [],
      label:false,
      newHospital: [],
      totalUsers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      sortBy: "name",
      sex_id: '',
      identified: '',
      donor_status_id: '',
      orgons:[],
      disabled: true,
      message: "",
      snackbar: false,
      loadingBTN: false,
      hospitalSelected: "",
      id: "",
      exibirMensagem: false,
      messageError: "",
      donorForm: {},
      orgonsTable:[],
      kinships:[],
      hospital:[],
      countries:[],
      hospitalRegistred:[],
      state:[],
      vue: {
        identified: null,
        hasCpf: null,
        minor: null,
        donor_status_id: null
      },
      formRules: [
        (v) => !!v || "Este campo é obrigatório",
      ],
      
    };
  },

  components: {
    VisualizarInformesClinicos,
    VisualizarSorologia,
    VisualizarAnexos,
},
  
  prop: {
    resource: {
      type: Object,
    },
  },

  created() {

    this.label = "Exclusão",
    this.$route.query.item.hospital_id = parseInt(this.$route.query.item.hospital_id)
    this.$route.query.item.country_id = parseInt(this.$route.query.item.country_id)
    this.$route.query.item.state_id = parseInt(this.$route.query.item.state_id)
    this.$route.query.item.kinship_id = parseInt(this.$route.query.item.kinship_id)
    this.$route.query.item.birth_date = FormatDate.dataAtualFormatada(this.$route.query.item.birth_date)
    this.donorForm = {...this.$route.query.item}
    this.orgons = [...this.$route.query.item.orgon]

    //disabled cpf
    if(this.donorForm.cpf){
      this.vue.hasCpf = 'true';
    } else {
      this.vue.hasCpf = 'false';
    }

    //disabled minor
    if(this.donorForm.responsible){
      this.vue.minor = 'true';
    }else{
      this.vue.minor = 'false';
    }
   
    //disabled identified
    if(this.donorForm.complement){
      this.vue.identified = 'true';
    }else{
      this.vue.identified = 'false';
    }
    
    axios.get("/unities").then((response) => {
    this.hospital = response.data.data;
    
    });

    axios.get("/kinship").then((response) => {
      this.kinships = response.data.data;
    });

    axios.get("/state").then((response) => {
      this.state = response.data.data;
    });
    
    axios.get("/countries").then((response) => {
      this.countries = response.data.data;
    });

    axios.get("/orgon").then((response) => {
      this.orgonsTable = response.data.data;
    });
  },

  watch: {

    sortBy: {
      handler() {

      }
    },

    options: {
      handler() {
        this.indoDataTable();
      },
    },

    deep: true,
  },

  computed:{
    hospital_id(){
      return this.donorForm.hospital_id;
    }
  },
  
  methods: {

    indoDataTable() {
      this.loading = true;
    },

    openCreateDoador() {
      this.dialog = true;
    },

    deleteOrgon(){
      //alert("dá pra deletar")
    },

    editDoador(){
      if(this.$refs.form.validate()) {
        const doador = (this.donorForm)
        axios
        .put(`/donor/${doador.id}`, doador)
        .then((response) => {
            this.displaySuccess.message = response.data.message;
            this.displaySuccess.display = true;
        })
        .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          })
      }
    },

    mounted() {
      this.indoDataTable();
    },
  }
};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.spacediferent {
  width: 7px;
}

.space-personalized {
  padding: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.button{
  padding: 25px 15px 18px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #27ae60,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #ee106d,
      #e46987);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.icon {}

.banner>h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
